// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".bg-mid{background-color:#f2f2f2!important}.mixin-base-bordered-card{border-radius:4px;box-shadow:0 4px 10px rgba(0,0,0,.04)}.mixin-elevated{box-shadow:0 80px 80px rgba(0,0,0,.075),0 10px 10px rgba(0,0,0,.035)!important}.mixin-gradient-overlay{width:100%;height:3rem;background:linear-gradient(0deg,#fff,transparent)}.mixin-gradient-overlay,.mixin-gradient-overlay-right{content:\"\";position:absolute;pointer-events:none;bottom:0}.mixin-gradient-overlay-right{width:3rem;height:100%;background:linear-gradient(270deg,#fff,transparent)}.text-ellipsis{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;max-width:calc(100% - 8px)}.bulk-invite-modal .invite-row{margin-top:16px}.bulk-invite-modal .invite-row:first-of-type{margin-top:0;background-color:red}", ""]);
// Exports
exports.locals = {
	"zDrawer": "1000",
	"zPopup": "1060",
	"zGraphAnnotationPrompt": "99"
};
module.exports = exports;
